import styled from "styled-components";
import { Link } from "gatsby";
import { Map } from "styled-icons/boxicons-solid/Map";
import { Work } from "styled-icons/material/Work";
import { AttachMoney } from "styled-icons/material/AttachMoney";
import { ChartLine } from "styled-icons/fa-solid/ChartLine";
import media from "styled-media-query";

export const MapMarker = styled(Map)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const SuitCaseIcon = styled(Work)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const AttachMoneyIcon = styled(AttachMoney)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const GraduationIcon = styled(ChartLine)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const JobItemLink = styled(Link)`
  color: var(--esx-black);
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &:hover {
    background: white;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  ${media.lessThan("medium")`
    padding: 1rem;
  `}
`;

export const JobItemWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const JobTagsWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  font-size: 0.7rem;
  width: 100%;
  padding-top: 1rem;
`;
export const JobTag = styled.li`
  text-align: center;
`;
export const JobItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const JobItemIcons = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  text-align: left;
  width: 100%;
  p {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    font-size: 0.7rem;
  }
  ${media.lessThan("medium")`
    grid-gap: .5rem;
    padding-top: 1rem;
  `}
`;

export const JobItemDate = styled.time`
  font-size: 0.7rem;
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;

export const JobItemTitle = styled.h1`
  font-size: 1.3rem;
  color: var(--esx-red);
  font-weight: 700;
  ${media.lessThan("medium")`
    font-size: 1rem;
  `}
`;
export const JobItemButtons = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  gap: 0.5rem;
  ${media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;
export const JobItemExternalLink = styled.a`
  width: 100%;
  color: var(--esx-red);
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 3rem;
  font-weight: bold;
  border: 2px solid var(--esx-red);

  &:hover {
    color: var(--esx-red);
  }
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;
export const JobItemButton = styled(Link)`
  width: 100%;
  color: #fafafa;
  font-size: 0.8rem;
  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  font-weight: bold;
  background: var(--esx-red);
  &:hover {
    color: #fafafa;
  }
  ${media.lessThan("medium")`
    font-size: .7rem;
  `}
`;

export const JobItemDescription = styled.p`
  display: -webkit-box;
  min-height: 3rem;
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: 300;
  line-height: 1.2;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan("medium")`
    margin-top: 1rem;
    font-size: .9rem;
  `}
`;

export const JobItemBorder = styled.hr`
  border-radius: 5px 5px 0 0;
  width: 100%;
  background-color: transparent;
  margin: auto;
  margin-top: 1rem;
  border-bottom: 0.5rem solid var(--esx-red);
  ${media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;
