import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import CtaBanner from "../components/CtaBanner";
import * as S from "./job-list/styled";
import { graphql } from "gatsby";
import JobItem from "../components/JobItem";
import BorderedTitle from "../components/BorderedTitle";
import { useState, useEffect } from "react";
import HeroSection from "../components/HeroSection";

const JobList = (props) => {
  const jobList = props.data.jobs.nodes;

  const esxTheme = {
    main: "var(--esx-red)",
    color: "#fafafa",
    gradient: "linear-gradient(var(--esx-red), var(--esx-red))",
    textColor: "var(--esx-dark)",
  };

  //state hooks
  const [searchText, setSearchText] = useState("");
  const [jobsFiltered, setJobsFiltered] = useState(jobList);
  useEffect(() => {
    const jobsResult = () => {
      if (searchText) {
        const exp = new RegExp(searchText.trim(), "i");
        return jobList.filter(
          (job) =>
            exp.test(job.frontmatter.title) ||
            exp.test(job.frontmatter.level) ||
            exp.test(job.frontmatter.contract) ||
            exp.test(job.frontmatter.locale) ||
            exp.test(job.frontmatter.skills.map((e) => e))
        );
      } else {
        return jobList;
      }
    };
    setJobsFiltered(jobsResult);
  }, [searchText, jobList]);

  return (
    <Layout>
      <SEO
        title="Vagas"
        image={props.data.seoImg}
        description="Faça parte da equipe ESX"
      />
      <HeroSection
        theme={esxTheme}
        fixedImage={props.data.headerImg.childImageSharp.fluid}
        title="Faça parte da equipe ESX"
        text="Uma empresa dedicada com o seu desenvolvimento pessoal e profissional"
      ></HeroSection>
      <S.SectionWrapper>
        <S.InputWrapper>
          <S.SearchInputLabel htmlFor="search-input">
            <S.SearchIcon />
          </S.SearchInputLabel>
          <S.SearchInput
            id="search-input"
            className="input"
            placeholder={`Buscar vaga. Ex: "Devops"`}
            onInput={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </S.InputWrapper>
        {jobsFiltered.length > 0 ? (
          <S.ItemsWrapper>
            {jobsFiltered.map(
              ({
                frontmatter: {
                  date,
                  description,
                  title,
                  locale,
                  level,
                  salary,
                  skills,
                  contract,
                },
                fields: { slug },
              }) => (
                <JobItem
                  slug={slug}
                  date={date}
                  title={title}
                  description={description}
                  locale={locale}
                  level={level}
                  salary={salary}
                  skills={skills}
                  contract={contract}
                />
              )
            )}
          </S.ItemsWrapper>
        ) : (
          <S.NoResultsAlert>
            Sem resultados para "{searchText}"
          </S.NoResultsAlert>
        )}
      </S.SectionWrapper>
      {/* <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numJobsPages}
        prevPage={prevPage}
        nextPage={nextPage}
      /> */}
      <CtaBanner
        title="Não encontrou uma vaga?"
        subtitle="Entre para nosso banco de talentos"
        buttonText="Banco de talentos"
        buttonLink="/banco-de-talentos"
        openAsExternalLink
      />
    </Layout>
  );
};

export const query = graphql`
  query JobList($skip: Int!, $limit: Int!) {
    jobs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//collections/jobs//" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          date(
            formatString: "DD[ de ]MMMM[ de ]YYYY[, às ]HH[:]MM"
            locale: "pt-br"
          )
          description
          title
          contract
          level
          locale
          salary
          skills
        }
        fields {
          slug
        }
      }
    }
    headerImg: file(relativePath: { eq: "cover_vagas_960x720px.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 720, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    seoImg: file(relativePath: { eq: "thumbnail_vagas_500x300px.png" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          src
        }
      }
    }
  }
`;

export default JobList;
