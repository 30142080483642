import React from "react";
import PropTypes from "prop-types";

import * as S from "./styled";

const JobItem = ({
  slug,
  date,
  title,
  description,
  locale,
  level,
  salary,
  skills,
  contract,
}) => (
  <S.JobItemLink to={slug}>
    <S.JobItemWrapper>
      <S.JobItemInfo>
        <S.JobItemTitle>{title}</S.JobItemTitle>
        <S.JobItemDate>{`${date}`}</S.JobItemDate>
        <S.JobItemIcons>
          <p>
            <S.MapMarker />
            {locale}
          </p>
          <p>
            <S.GraduationIcon />
            {level}
          </p>
          <p>
            <S.SuitCaseIcon />
            {contract}
          </p>
          <p>
            <S.AttachMoneyIcon />
            {salary > 0 ? `R$ ${salary.toLocaleString()}` : "A combinar"}
          </p>
        </S.JobItemIcons>
        <S.JobItemDescription>{description}</S.JobItemDescription>
      </S.JobItemInfo>
      <S.JobTagsWrapper>
        {skills.slice(0, 5).map((skill, i) => (
          <S.JobTag key={i} title={skill}>
            {skill}
          </S.JobTag>
        ))}
        {skills.length > 5 ? (
          <S.JobTag>{`+ ${skills.length - 5}`}</S.JobTag>
        ) : null}
      </S.JobTagsWrapper>
      <S.JobItemButtons>
        <S.JobItemButton to={slug}>Detalhes</S.JobItemButton>
      </S.JobItemButtons>
    </S.JobItemWrapper>
  </S.JobItemLink>
);

JobItem.propTypes = {
  slug: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  locale: PropTypes.string,
  level: PropTypes.string,
  salary: PropTypes.string,
  skills: PropTypes.array,
  contract: PropTypes.string,
};

export default JobItem;
